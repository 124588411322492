.shader {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden; /* to force GPU performance */
  }

.shader__layer {
    background: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: center;
  }

  .specular {
    mix-blend-mode: color-dodge;
    background-attachment: fixed;
    background-image: linear-gradient(180deg, black 20%, #3c5e6d 35%, #f4310e, #f58308 80%, black);
  }

  .mask {
    mix-blend-mode: multiply;
    background-image: url('../images/splash.jpg');
  }
